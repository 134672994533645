<template>
  <div class="aa">
    <div v-for="(item, index) in anList" :key="index">
      <vue-hover-mask class="all">
        <template slot="action">
          <div class="box">
            <div class="Img" @mouseenter="test" @mouseleave="test">
              <img
                :src="flag ? one : two"
                alt=""
                @click="showModal(item.uuid)"
              />
            </div>
            预览
          </div>
        </template>
        <div class="Limg">
          <img mode="aspectFill" :src="item.anchor_cover" />
        </div>
        <div class="Re">
          <div class="top">
            <p>{{ item.anchor_name }}</p>
            <div class="ftxt">
              资料完整度：<span
                style="color:#2990F9;"
                :class="item.complete_ratio < 50 ? 'red' : ''"
                >{{ item.complete_ratio }}%</span
              >
            </div>
            <div class="ftxt">
              所属王牌运营：<span style="color:#333">{{ item.broker }}</span>
            </div>
            <div
              style="width: 80%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;color: #262626;"
              class="ftxt"
            >
              备注：<span style="color:#F30016;">{{
                item.apply_description
              }}</span>
            </div>
          </div>
          <div class="bot">
            <p>
              推荐人：<span style="color: #333;" @click="goInfo(item.uuid)">{{
                item.scout
              }}</span>
            </p>
            <p v-show="tab == 1">
              推荐人手机号：<span
                style="color:#2990F9;"
                @click="call(item.scout_phone)"
                >{{ item.scout_phone }}</span
              >
            </p>
            <p v-show="tab == 2">
              推荐人手机号：<span style="color:#2990F9;">{{
                item.scout_phone
              }}</span>
            </p>
          </div>
        </div>
      </vue-hover-mask>
    </div>
    <el-dialog
      :visible.sync="anDialogVisible"
      width="65%"
      :show-close="false"
      :title="null"
      center
    >
      <div class="anDialog">
        <div class="close" @click="anDialogVisible = false">
          <i class="el-icon-close"></i>
        </div>
        <div class="top">
          <div class="title">{{ dialogList.anchor_name }}<img v-show="tab==1" src="@/static/starImg/edit (2).png" style="margin-left:5px;cursor:pointer" @click="goTj()"/></div>
          <div class="bbox">
            <div class="left">
              <p>推荐人：{{ dialogList.scout }}</p>
              <p>推荐人手机号：{{ dialogList.scout_phone }}</p>
              <p>所属王牌运营：{{ dialogList.broker }}</p>
            </div>
            <div class="right">
              <p>
                经验：<span style="color:#2990F9"
                  >{{ dialogList.broadcasting_month }}月</span
                >
              </p>
              <p>
                意向平台：<span style="color:#2990F9">{{dialogList.plate_form_name?
                  dialogList.plate_form_name.join('、') :''
                }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="oline"></div>
        <div class="sImg">
          <div class="stitle">主播照片</div>
          <div class="imgwrap">
            <div
              class="oimg"
              v-for="(item, index) in dialogList.anchor_medias"
              :key="index"
              @click="showDialog(item.src, item.type)"
            >
              <img
                :src="
                  item.src +
                    (item.type == 'image'
                      ? ''
                      : '?x-oss-process=video/snapshot,t_0,f_jpg,m_fast')
                "
                alt=""
              />
              <div class="play_icon" v-if="item.type == 'video'"></div>
            </div>
            <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
              <div style="padding:20px">
                <img
                  v-if="dialogType == 'image'"
                  width="100%"
                  :src="dialogImageUrl"
                  alt=""
                />
                <video
                  v-if="dialogType == 'video'"
                  :src="dialogImageUrl"
                  controls
                  style="width:100%"
                ></video>
              </div>
            </el-dialog>
          </div>
          <div class="bot">
            <div class="phone" style="text-align:left;">
              <div class="line">
                <img
                  style="width: 11px; height: 14px;padding-top:3px;"
                  src="../../../../static/starImg/bj (4).png"
                />
                <span>手机号</span>
              </div>
              <p>{{ dialogList.anchor_phone }}</p>
            </div>
            <div class="phone" style="text-align:left;">
              <div class="line">
                <img
                  style="width: 11px; height: 14px;padding-top:3px;"
                  src="../../../../static/starImg/bj (5).png"
                />
                <span>微信号</span>
              </div>
              <p>{{ dialogList.anchor_wechat }}</p>
            </div>
          </div>
          <div class="txtbox" v-show="tab == 2">
            <div class="cebox" @click="txtShow = false" v-if="txtShow">
              <img src="@/static/starImg/bj (1).png" />
              <span>留言备注</span>
            </div>
            <textarea v-else style="resize:none;" max-length="30"></textarea>
          </div>
          <div class="txtbox" v-show="tab == 1">
            {{ dialogList.apply_description?dialogList.apply_description:'暂无备注' }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VueHoverMask from "@/components/vue-hover-mask/hovermask.vue";
export default {
  components: {
    VueHoverMask
  },
  props: {
    tab: {
      type: String,
      default: "1"
    },
    anchorList: {
      type: Array,
      default: () => []
    },
    anchorList1: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: "",
      dialogType: "",
      txtShow: true,
      dialogList: [],
      anDialogVisible: false,
      flag: true,
      one: require("../../../../static/starImg/bj (6).png"),
      two: require("../../../../static/starImg/bj (7).png")
    };
  },
  computed: {
    anList() {
      if (this.tab == 1) {
        //收到的推荐主播
        return this.anchorList;
      }
     else  {
        //推荐出去的主播
        return this.anchorList1;
      }
    }
  },
  filters: {
    strFilter(str) {
      str = [];
      let s = str.join("、");
      return s;
    }
  },
  mounted() {
    console.log(this.tab);
  },
  methods: {
    test() {
      this.flag = !this.flag;
    },
    goTj(){
      this.$router.push({
        path: "/star/toBroker",
        name:'toBroker',
        params: {
          item: this.dialogList
        }
      });
    },
    showDialog(src, type) {
      this.dialogVisible = true;
      console.log(src);
      this.dialogImageUrl = src;
      this.dialogType = type;
    },
    async showModal(uuid) {
      this.anDialogVisible = true;
      const { data, status } = await this.$api.anRecInfoApi({
        uuid
      });
      if (status == 200) {
        console.log(data);
        this.dialogList = data;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.aa {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.mask {
  z-index: 100;
}
/deep/ .el-dialog__headerbtn {
  position: absolute;
  top: 4px;
  right: 5px;
}
.all {
  width: 430px;
  margin: 0 20px 20px 0;
  background: #fff;
  display: flex;
  position: relative;

  .Limg {
    width: 176px;
    height: 176px;
    border-radius: 4px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }

  .btn {
    position: absolute;
    bottom: 24px;
    right: 3%;
    width: 100px;
    height: 44px;
    line-height: 44px;
    background: #f5f5f5;
    border-radius: 22px;
    background-color: #f5f5f5;
    font-size: 12px;
    color: #2990f9;
    text-align: center;
  }

  .Re {
    margin-left: 20px;

    .top {
      margin-bottom: 12px;

      .ftxt {
        font-size: 14px;
        color: #666;
        height: 20px;
        line-height: 20px;
        margin-bottom: 4px;
      }

      p {
        font-size: 18px;
        font-weight: 500;
        color: #262626;
        height: 25px;
        line-height: 25px;
        margin-bottom: 6px;
        margin-top: 5px;
      }
    }

    .bot {
      p {
        font-size: 14px;
        color: #666666;
        height: 20px;
        line-height: 20px;
        margin-bottom: 4px;

        span {
          font-size: 13px;
          color: #2990f9;
        }
      }
    }
  }
}

.red {
  color: #df2a29 !important;
}

.blue {
  color: #999999 !important;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  font-size: 16px;
  color: #fff;
  .Img {
    width: 50px;
    height: 50px;
    margin-bottom: 3px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.anDialog {
  padding: 50px;
  position: relative;
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
    color: #999999;
  }
  .top {
    .title {
      height: 33px;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 33px;
      margin-bottom: 16px;
    }
    .bbox {
      display: flex;
      .left {
        width: 350px;
        p {
          height: 20px;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          margin-bottom: 12px;
        }
      }
      .right {
        p {
          height: 20px;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          margin-bottom: 12px;
        }
      }
    }
  }
  .oline {
    height: 1px;
    background: #eeeeee;
    margin-top: 14px;
  }
  .sImg {
    .stitle {
      margin: 20px 0;
      height: 25px;
      font-size: 16px;

      color: #333333;
      line-height: 25px;
    }
    .imgwrap {
      display: flex;
      flex-wrap: wrap;
      .oimg {
        width: 100px;
        height: 100px;
        margin: 0 20px 20px 0;
        border-radius: 4px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
        .play_icon {
          width: 30px;
          height: 30px;
          background-image: url(../../../../static/starImg/play.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: absolute;
          top: 35px;
          left: 35px;
          z-index: 100;
        }
      }
    }
  }
  .bot {
    display: flex;
    .phone {
      width: 340px;
      height: 80px;
      .line {
        margin: 15px 0 6px 0;
        height: 20px;
        line-height: 20px;
        span {
          height: 20px;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          margin-left: 4px;
        }
      }
      p {
        height: 25px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
      }
    }
  }
  .txtbox {
    width: 100%;
    height: 160px;
    background-image: url(../../../../static/starImg/bj.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    textarea {
      width: 100%;
      height: 100%;
      border: 1px solid #eee;
      text-align: center;
      line-height: 147px;
    }
    .cebox {
      height: 22px;
      font-size: 16px;
      color: #333333;
      display: flex;
      align-items: center;
      img {
        margin-right: 3px;
        line-height: 22px;
      }
    }
  }
}
/deep/ .el-dialog__header {
  padding: 0px;
}
/deep/ .el-dialog__body {
  padding: 0;
}
</style>